import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Ласкаво просимо до Aniliun Pub
			</title>
			<meta name={"description"} content={"Пориньте у дружню атмосферу The Aniliun Pub, притулку для спортивних ентузіастів, які цінують поєднання живої гри, дружніх жартів та пінту найкращого елю. Це не просто паб - це клуб, де кожна гра є домашньою."} />
			<meta property={"og:title"} content={"Головна | Ласкаво просимо до Aniliun Pub"} />
			<meta property={"og:description"} content={"Пориньте у дружню атмосферу The Aniliun Pub, притулку для спортивних ентузіастів, які цінують поєднання живої гри, дружніх жартів та пінту найкращого елю. Це не просто паб - це клуб, де кожна гра є домашньою."} />
			<meta property={"og:image"} content={"https://aniliunbast.com/img/banner.jpg"} />
			<link rel={"shortcut icon"} href={"https://aniliunbast.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://aniliunbast.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://aniliunbast.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://aniliunbast.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://aniliunbast.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://aniliunbast.com/img/icon.png"} />
			<meta name={"msapplication-TileColor"} content={"https://aniliunbast.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="160px 0 170px 0" background="linear-gradient(0deg,rgba(255, 255, 255, 0.23) 0%,rgba(255, 255, 255, 0.23) 100%),rgba(0, 0, 0, 0) url(https://aniliunbast.com/img/banner.jpg) 0% 0% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-9">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="normal 700 64px/1.2 --fontFamily-sansTrebuchet"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
				>
					Ласкаво просимо до Aniliun Pub
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#50555a"
					padding="0px 150px 0px 150px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Пориньте у дружню атмосферу The Aniliun Pub, притулку для спортивних ентузіастів, які цінують поєднання живої гри, дружніх жартів та пінту найкращого елю. Це не просто паб - це клуб, де кожна гра є домашньою.
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					sm-flex-direction="column"
					sm-align-self="stretch"
					justify-content="center"
					align-items="center"
					text-align="center"
				>
					<Button
						margin="0px 15px 0px 0px"
						padding="12px 28px 12px 28px"
						background="#d83124"
						border-radius="8px"
						font="normal 400 17px/1.5 --fontFamily-sans"
						sm-margin="0px 0 15px 0px"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="solid"
						border-color="#d83124"
						hover-background="rgba(63, 36, 216, 0)"
						hover-color="--darkL1"
						href="/contact-us"
						type="link"
						text-decoration-line="initial"
					>
						Приходьте до нас
					</Button>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" background="--color-light">
			<Box
				margin="0px 0px 32px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 33px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
				lg-padding="15px 15px 15px 15px"
				sm-margin="0px 0px 10px 0px"
				justify-content="center"
			>
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Наш унікальний досвід
				</Text>
				<Text
					font="--lead"
					color="--darkL2"
					text-align="center"
					md-width="100%"
					max-width="1200px"
				>
					Оточіть себе кількома екранами, що демонструють спортивні події з усього світу, щоб не пропустити жодної миті. Беріть участь у вечорах спортивних дебат, що розбурхують мозок, з питаннями від історичних спортивних фактів до поточних спортивних подій.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://aniliunbast.com/img/1.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Куточок більярдних акул
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						Візьміть кий для гри в більярд, де ви можете влаштувати дружнє змагання або відточити свої навички наодинці.
					</Text>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://aniliunbast.com/img/2.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Два рази на місяць "Пий і дивись"
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						Насолоджуйтесь кураторськими дегустаціями пива в поєднанні з класичними спортивними фільмами та документальними стрічками.
					</Text>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0" quarkly-title="Schedule-8">
			<Override
				slot="SectionContent"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px 30px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="30px"
				md-grid-gap="32px"
			/>
			<Box
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				align-items="center"
				display="flex"
				grid-column="1 / span 3"
				lg-grid-column="auto / auto"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
				>
					Гості діляться враженнями
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--darkL2"
					md-text-align="center"
					text-align="center"
					max-width="800px"
					sm-font="normal 400 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
				>
					Aniliun Pub - це не просто місце, це спільнота, де рахунок на табло завжди свідчить на користь чудового проведення часу. Не просто вірте нам на слово - читайте далі, щоб дізнатися, чому наші гості повертаються сюди знову і знову.
				</Text>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					"Я бував у спортивних пабах по всьому світу, але саме в The Aniliun Pub я по-справжньому відчув себе частиною гри. Тут відчутна енергетика, а товариська атмосфера не має собі рівних. Це моє улюблене місце на кожен ігровий день!"
				</Text>
				<Box
					display="flex"
					grid-template-columns="1fr 1fr"
					grid-gap="16px"
					align-items="center"
					justify-content="flex-start"
				>
					<Image
						src="https://aniliunbast.com/img/p1.jpg"
						display="block"
						width="44px"
						height="44px"
						object-fit="cover"
						border-radius="50%"
						object-position="0% 0%"
					/>
					<Box>
						<Text margin="0px 0px 0px 0px" font="normal 700 20px/1.5 --fontFamily-sans" color="--darkL1">
							Олександр Бондаренко
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					"З кожним відвідуванням я знаходжу нову улюблену страву в меню та нового друга за барною стійкою. Це місце - серце ігрового дня".
				</Text>
				<Box
					display="flex"
					grid-template-columns="1fr 1fr"
					grid-gap="16px"
					align-items="center"
					justify-content="flex-start"
				>
					<Image
						src="https://aniliunbast.com/img/p2.jpg"
						display="block"
						width="44px"
						height="44px"
						object-fit="cover"
						border-radius="50%"
						object-position="0% 0%"
					/>
					<Box>
						<Text margin="0px 0px 0px 0px" font="normal 700 20px/1.5 --fontFamily-sans" color="--darkL1">
							Олена Омелян
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				padding="40px 40px 40px 40px"
				lg-padding="45px 30px"
				md-padding="45px 45px"
				background="#FFFFFF"
				border-radius="24px"
				justify-content="flex-start"
				flex-direction="column"
				display="flex"
			>
				<Text
					margin="0px 0px 46px 0px"
					color="--greyD3"
					font="--base"
					lg-text-align="left"
					flex="1 0 auto"
				>
					"Незалежно від того, чи це фінал, чи товариська зустріч, The Aniliun Pub - це місце, де я святкую дух гри. Атмосфера завжди вирує, а їжа така ж сенсаційна, як і переможний гол, забитий на останній хвилині. Це місце, де вболівальник стає сім'єю".
				</Text>
				<Box
					display="flex"
					grid-template-columns="1fr 1fr"
					grid-gap="16px"
					align-items="center"
					justify-content="flex-start"
				>
					<Image
						src="https://aniliunbast.com/img/p3.jpg"
						display="block"
						width="44px"
						height="44px"
						object-fit="cover"
						border-radius="50%"
						object-position="0% 0%"
					/>
					<Box>
						<Text margin="0px 0px 0px 0px" font="normal 700 20px/1.5 --fontFamily-sans" color="--darkL1">
							Семен Прокопович
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});